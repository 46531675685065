.siderContainer {
  position: fixed;
  width: 340px;
  padding: 30px 30px 0;
  z-index: 200;
  .dropLogo {
    width: 45px;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  .menu {
    margin-top: 84px;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 35px;
  }
  .menuItem {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    opacity: 0.3;
    cursor: pointer;
    transition: 0.3s all ease;
    &:hover {
      opacity: 1;
    }
    &.active {
      opacity: 1;
    }
    &.disabled {
      opacity: 0.3;
      cursor: default;
      &:hover {
        opacity: 0.3;
      }
    }
  }
  .menuText {
    color: white;
    margin-left: 15px;
    opacity: 0;
    &.visible {
      opacity: 1;
    }
  }
  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }
  .subtitle {
    font-size: 12px;
    color: #9797a4;
    font-weight: 500;
  }
  .gradientText {
    background: linear-gradient(
      280deg,
      #e8e3e3 67.85%,
      #9f9f9f 74.83%,
      #b3f9a7 79.49%,
      #c3c2ff 84.18%,
      #e4e4e4 88.32%,
      #fcfbfc 92.91%,
      #ffffff 96.56%,
      #e7e7e7 103.07%,
      #f6f6f6 109.12%,
      #ffffff 113.57%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
@media (max-height: 800px), (max-width: 1340px) {
}

@media (max-width: 860px) {
  .siderContainer {
    display: none;
  }
}
