.button {
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	padding: 22px 22px;
	border-radius: var(--input-border-radius);
	background: var(--primary-color);
	background: linear-gradient(
		125.08deg,
		rgba(144, 144, 144, 0.8) -114.13%,
		rgba(32, 33, 36, 0.8) 140.98%
	);
	color: var(--primary-button-text-color);
	width: 100%;
	font-size: 18px;
	font-weight: 600;
	letter-spacing: -0.5px;
	text-decoration: none;
	cursor: pointer;
	transition: 0.2s all ease;
	color: white;
	font-weight: 400;
	font-size: 16px;

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
	&:not(:disabled):hover {
		opacity: 0.8;
	}
	.icon {
		margin: 0 15px 0 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.suffixIcon {
		margin-left: 20px;
		top: 1px;
		position: relative;
	}
	&.white {
		padding: 19px 32px;
		background: white;
		border: 1px solid black;
		color: #363a3e;
		letter-spacing: 0;
		font-weight: 500;
	}
	&.small {
		width: auto;
		font-size: 15px;
	}
	&.darkGradient {
		padding: 19px 32px;
		background: linear-gradient(
				175deg,
				rgba(0, 0, 0, 0.9) 0%,
				rgba(0, 0, 0, 0.7) 100%
			),
			linear-gradient(
				34deg,
				#e8e3e3 -7.09%,
				#f0e0e0 15.27%,
				#fff778 28.53%,
				#dbfff6 40.38%,
				#e4e4e4 50.26%,
				#fcfbfc 63.13%,
				#ffffff 73.36%,
				#eaecec 91.73%,
				#f6f6f6 108.54%,
				#ffffff 121.02%
			);
		border: 1px solid white;
		color: white;
		letter-spacing: 0;
		font-weight: 500;
	}
	&.gradient {
		padding: 19px 32px;
		background: linear-gradient(
			125.08deg,
			rgba(144, 144, 144, 0.8) -114.13%,
			rgba(32, 33, 36, 0.8) 140.98%
		);
		border: none;
		letter-spacing: -0.5px;
	}
}

.buttonWrapper {
	padding: 1px;
	position: relative;
	border-radius: var(--input-border-radius);
}
