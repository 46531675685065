@font-face {
	font-family: 'Inter';
	src: url('./assets/fonts/Inter-Regular.woff2') format('woff2'),
		url('./assets/fonts/Inter-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Inter';
	src: url('./assets/fonts/Inter-Medium.woff2') format('woff2'),
		url('./assets/fonts/Inter-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Inter';
	src: url('./assets/fonts/Inter-SemiBold.woff2') format('woff2'),
		url('./assets/fonts/Inter-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Inter';
	src: url('./assets/fonts/Inter-Bold.woff2') format('woff2'),
		url('./assets/fonts/Inter-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

:root {
	--regular-font: 'inter-regular', Helvetica, Arial, sans-serif;
	--medium-font: 'inter-medium', Helvetica, Arial, sans-serif;
	--semibold-font: 'inter-semibold', Helvetica, Arial, sans-serif;
	--bold-font: 'inter-bold', Helvetica, Arial, sans-serif;
	--button-font: 'inter-semibold', Helvetica, Arial, sans-serif;
	--input-font: 'inter-medium', Helvetica, Arial, sans-serif;
	--mobile-breakpoint: 1024px;
	--default-background-color: #fcfcfc;
	--title-text-color: #262338;
	--text-color: #212121;
	--subtitle-text-color: #9797a4;
	--default-font-size: 16px;
	--title-font-size: 52px;
	--primary-color: #151aff;
	--primary-button-text-color: #ffffff;
	--primary-button-border-radius: 8px;
	--input-border-radius: 8px;
	--alert-text-color: #ff045b;
	--alert-background-color: #ffe7ef;
	--step-title-color: #8d969f;
}

body {
	margin: 0;
	font-weight: 400;
	color: var(--text-color);
	font-size: var(--default-font-size);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #000000;
}

body,
html {
	font-family: 'Inter', Arial, Arial, Helvetica, sans-serif;
	width: 100%;
	height: 100%;
}

h1 {
	// font-weight: 700;
	// font-size: var(--title-font-size);
	// color: white;
	// background: linear-gradient(
	// 	30deg,
	// 	#e8e3e3 1.62%,
	// 	#9f9f9f 18.77%,
	// 	#b3f9a7 30.21%,
	// 	#c3c2ff 41.71%,
	// 	#e4e4e4 51.87%,
	// 	#fcfbfc 63.15%,
	// 	#ffffff 72.12%,
	// 	#e7e7e7 88.1%,
	// 	#f6f6f6 102.94%,
	// 	#ffffff 113.88%
	// );
	// -webkit-background-clip: text;
	// -webkit-text-fill-color: transparent;
	// margin: 0;
	// line-height: 48px;
	// letter-spacing: -1px;
}

h2 {
	font-weight: 700;
	font-size: 48px;
	color: white;
	margin: 0;
	line-height: 48px;
	letter-spacing: -1px;
}

a,
a:-webkit-any-link {
	color: inherit;
}

.drop-signup-page-container {
	min-height: 100vh;
	width: 100%;
	display: flex;
	background: #000000;
	background: linear-gradient(
		140.82deg,
		#1c1c1c 37.26%,
		rgba(28, 28, 28, 0) 93.99%
	);
}

.drop-signup-page-container::after {
	position: fixed;
	bottom: 0;
	content: '';
	height: 10px;
	width: 100vw;
	background: linear-gradient(
		90deg,
		#e8e3e3 0%,
		#9f9f9f 15.28%,
		#b3f9a7 25.47%,
		#c3c2ff 35.71%,
		#e4e4e4 44.77%,
		#fcfbfc 54.81%,
		#ffffff 62.8%,
		#e7e7e7 77.03%,
		#f6f6f6 90.26%,
		#ffffff 100%
	);
}

.errorLabel {
	color: var(--alert-text-color);
	font-size: 14px;
}

#root {
	height: 100% !important;
	display: flex !important;
	flex-direction: column !important;
	flex-grow: 1 !important;
}

@media (max-width: 940px) {
	#root {
		height: initial !important;
	}
}
