.inputWrapper {
	position: relative;
	display: flex;
	.input {
		border-radius: var(--input-border-radius);
		height: 34px;
		padding: 24px 20px 4px;
		margin-bottom: 15px;
		border: 1px solid rgba(89, 90, 92, 0.72);
		background: transparent;
		color: white;
		appearance: unset;
		outline: none;
		transition: 0.2s all ease;
		flex-grow: 1;
		font-size: 15px;
		line-height: normal;
		font-weight: 500;
		letter-spacing: 0px;
		&::placeholder {
			opacity: 0;
		}
		&:focus {
			border-radius: var(--input-border-radius);
		}
		&:disabled {
			opacity: 0.6;
			cursor: not-allowed;
			color: #9797a4;
		}
		&.hasError {
			border-color: #ffd500;
			color: #ffd500;
		}
		&.hasPrefix {
			padding: 24px 20px 4px 36px;
		}
	}
	.placeholder {
		position: absolute;
		top: 0;
		left: 0;
		color: rgba(255, 255, 255, 0.52);
		font-weight: 500;
		transition: 0.1s all ease;
		// margin: 24px 0 0 21px;
		// font-size: 16px;
		margin: 14px 0 0 21px;
		font-size: 13px;
		&.focused {
			margin: 14px 0 0 21px;
			font-size: 13px;
		}
		&.hasError {
			color: #ffd500;
		}
	}
	.suffix {
		position: absolute;
		top: 0;
		right: 0;
		margin: 24px 21px 0 0;
		cursor: pointer;
		color: white;
		opacity: 0.7;
		&.focused {
			color: white;
		}
		&.hasError {
			color: #ffd500;
		}
	}
	.prefix {
		position: absolute;
		top: 0;
		left: 0;
		margin: 31px 0 0 21px;
		opacity: 0;
		color: #9797a4;
		&.visible {
			opacity: 1;
		}
	}
}

input:-internal-autofill-selected {
	background: transparent !important;
	color: white !important;
}
