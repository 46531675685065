.nudeLink {
	text-decoration: none;
}

.titleContainer {
	text-align: center;
}

.backLink {
	position: absolute;
	top: 50px;
	left: 50px;
	color: #8d969f;
	font-size: 16px;
	font-weight: 600;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 20px;
	svg {
		transform: rotate(180deg);
		margin-right: 16px;
		width: 10px;
		height: 16px;
	}
}
.stepName {
	text-transform: uppercase;
	color: var(--step-title-color);
	font-weight: 700;
	margin-bottom: 20px;
}
.description {
	margin: 0 auto 10px;
	width: 285px;
	line-height: 24px;
	a {
		font-weight: 700;
		text-decoration: none;
		color: white;
	}
	&.large {
		width: 100%;
		font-weight: 600;
		span {
			color: var(--primary-color);
			font-weight: 700;
		}
	}
	&.forgotPassword {
		text-align: right;
		margin-bottom: 30px;
		font-size: 14px;
		opacity: 0.5;
	}
	&.signup {
		margin-bottom: 20px;
		font-weight: 400;
	}
	&.temporaryCode {
		margin-bottom: 30px;
		width: 530px;
	}
}
.subtitle {
	color: var(--subtitle-text-color);
	font-size: 14px;
	margin-bottom: 35px;
}
.separator {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 30px 0;
	.or {
		margin: 0 15px;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		color: var(--subtitle-text-color);
		font-size: 14px;
	}
	.line {
		background: var(--subtitle-text-color);
		height: 1px;
		flex-grow: 1;
	}
}
.illustration {
	width: 100%;
	text-align: center;
	margin-top: 24px;
}
.freeAccountButton {
	margin-bottom: 20px;
}
.digitsInputsContainer {
	display: flex;
	> div {
		margin-right: 10px;
	}
	> div:last-child {
		margin-right: 0;
	}
	input {
		width: 78px;
		height: 94px;
		min-width: 0;
		padding: 0;
		flex-grow: 0;
		font-weight: 700;
		font-size: 60px;
		text-align: center;
		&:not(:disabled) {
			background: white;
			border: 2px solid black;
		}
	}
	&.withError {
		input {
			border: 2px solid var(--alert-text-color);
			color: var(--alert-text-color);
		}
	}
}
.codeInfos {
	display: flex;
	justify-content: space-between;
	.errorLabel {
		flex-grow: 1;
	}
}
.resendCode {
	color: var(--primary-color);
	font-size: 14px;
	font-weight: 700;
	text-align: right;
	flex-grow: 1;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	& > div {
		margin-right: 6px;
	}
}
.codeCheckLoading {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px 0;
}
.loader {
	color: var(--primary-color);
	margin-right: 15px;
}

.errorLabel {
	font-weight: 500;
	color: var(--alert-text-color);
	font-size: 14px;
	padding: 10px;
}
.nudeLink {
	text-decoration: none;
}
.loginFallback {
	color: var(--primary-color);
	font-size: 14px;
	padding: 0 10px;
	margin-bottom: 20px;
	text-decoration: underline;
	cursor: pointer;
}

.passwordError {
	font-size: 12px;
	font-weight: 400;
	color: #ffd500;
	text-align: left;
	margin-bottom: 20px;
}

// @media (max-width: 1240px) {
//   .stepContainer {
//     width: calc(100% - 300px);
//     margin-left: 300px;
//   }
// }

// @media (max-width: 860px) {
//   .stepContainer {
//     width: 100%;
//     margin-left: 0;
//     padding: 30px;
//   }
//   .formContainer {
//     width: 100%;
//   }
// }
