.pageContent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100%;
	padding-top: 50px;
	margin-top: -50px;
	width: 85%;
}

.titleContainer {
	font-size: 16px;
	text-align: center;
	width: 80%;
	color: white;

	h1 {
		margin: 0;
	}
}

.formContainer {
	width: 75%;
	margin-top: 50px;
	&.email {
		margin-top: 80px;
	}
}

.subtitle {
	font-weight: 500;
	margin-top: 8px;
	text-align: center;
}

@media (max-width: 940px) {
	.pageContent {
		margin-top: -20px;
	}

	.formContainer {
		width: 100%;
		margin-top: 40px;
	}

	.titleContainer {
		width: 100%;
	}
}
