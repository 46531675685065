.loginRedirect {
	color: white;
	margin-top: 20px;
	font-weight: 400;
	a {
		font-weight: 700;
	}
}

.legal {
	color: #9797a4;
	font-size: 14px;
	text-align: left;
	font-weight: 500;
	margin-bottom: 14px;
}

.errorLabel,
.passwordError {
	font-size: 12px;
	font-weight: 400;
	color: #ffd500;
	text-align: left;
	margin-bottom: 20px;
}

.MetaLogo {
	position: absolute;
	bottom: 50px;
}
