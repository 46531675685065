.PageContainer {
	display: flex;
	flex-direction: column;
	height: 100vh;
	background-color: #f0f0f0; /* Stroke */
	padding: 30px;

	background: linear-gradient(137.12deg, #1c1d1f 37.65%, #434445 160.33%);
}

.Header {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	color: #c1c1c1;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	flex-basis: 24px;
	line-height: 20px;

	a {
		text-decoration: none;
	}
}

.Container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	flex-shrink: 0;
	min-width: 0;
	min-height: 0;
}

.SubContainer {
	background: linear-gradient(156.07deg, #393a3c -20.73%, #1c1d1f 92.26%);
	border: 1px solid rgba(89, 90, 92, 0.32);
	width: 80%;
	height: 100%;
	margin: 32px;
	border-radius: 12px;
	padding: 10px;
	overflow: hidden;
	position: relative;
	display: flex;
	max-width: 1200px;
}

.Left {
	width: 50%;
	height: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
	color: white;
}

.Right {
	background: linear-gradient(180deg, #27282a -11.21%, #000000 180.18%);
	border-radius: 12px;
	width: 50%;
	height: 100%;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.RightHeader {
	display: flex;
	text-align: center;
	justify-content: center;
	padding-top: 130px;

	h1 {
		font-size: 34px;
		width: 70%;
		color: white;
	}
}

.Emphase {
	color: #b3fc4f;
}
.HorizontalPart {
	color: white;
	font-size: 16px;
	padding: 40px;
	display: flex;
	align-items: flex-start; /* Align items at the start */

	.Quote {
		font-weight: 600;
		margin-left: 10px;
		align-self: flex-start;
		margin-left: 25px;
	}

	.QuotePictureContainer {
	}

	img {
		width: 80px; /* Set the width */
	}
}
.Pills {
	margin-top: 20px;

	div {
		color: black;
		font-weight: 600;
		font-size: 12px;
		padding: 6px 10px;
		border-radius: 12px;
		display: inline-block;
	}
}

.Pill1 {
	background: #b3fc4f;
}

.Pill2 {
	color: #b3fc4f !important;
	border: solid 1px #b3fc4f;
	margin-left: 5px;
}

.Zigouigoui1,
.Zigouigoui2,
.Zigouigoui3,
.Zigouigoui4,
.Zigouigoui5 {
	transform: scale(0.7);
	position: absolute;
}

.Zigouigoui1 {
	right: -70px;
	top: 70px;
}

.Zigouigoui2 {
	left: -70px;
	top: 50px;
}

.Zigouigoui3 {
	left: 0;
	top: -90px;
}

.Zigouigoui4 {
	right: -50px;
	top: -150px;
}

.Zigouigoui5 {
	right: 130px;
	top: -10px;
}

.RightFooter {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding: 0 10px;
	flex-direction: column;
}

.Rectangles {
	margin-bottom: -5px;
	width: 100%;
	max-width: 100%;
	height: 120px;
	align-self: flex-end;
	margin-right: 10px;

	.Img {
		img {
			object-fit: cover;
		}
	}
}

.Brands {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	margin-right: 15px;

	img {
		display: inline-block;
		object-fit: contain;
		transform: scale(0.9);
	}
}

@media (max-width: 940px) {
	.PageContainer {
		padding: 10px;
		height: 100%;
	}

	.Header {
		margin-top: 10px;
		margin-bottom: 15px;
	}

	.Container {
		flex-direction: initial;
		align-items: initial;
		justify-content: initial;
		flex: initial;
		min-width: initial;
		min-height: initial;
	}

	.SubContainer {
		margin: 0;
		flex-direction: column;
		width: 100%;
		padding: 0;
	}

	.Left {
		width: 100%;
		margin-bottom: 40px;
	}

	.Right {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.Pill2 {
		margin-left: 0;
		margin-top: 4px;
	}

	.formContainer {
		width: 100%;
	}

	.Rectangles {
		height: initial;
	}
}
