.providerCallbackContent {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: -50px;
	padding-top: 50px;
	width: 460px;
}

.loader {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.cancel {
	background: none;
	color: white;
	opacity: 0.8;
	border: none;
	font-size: 14px;
	margin-top: 20px;
	cursor: pointer;
}
