.instagramInfos {
	background: transparent;
	display: flex;
	padding: 12px 20px 12px 20px;
	border-radius: 8px;
	border: 1px solid rgba(89, 90, 92, 0.72);
	color: white;
	&.noData {
		opacity: 0.3;
		> * {
			opacity: 0.3;
		}
	}
	&.isLoading {
		opacity: 1;
		> * {
			opacity: 1;
		}
	}
	.leftContent {
		display: flex;
		align-items: center;
	}
	.rightContent {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		margin-left: 20px;

		.dataItem {
			margin-right: 30px;
			font-size: 13px;

			.number {
				font-weight: 700;
				font-size: 14px;
				display: inline-block;
			}
			.label {
				font-size: 14px;
			}
		}
	}
}
.dataItemContainer {
	margin-top: 5px;
	display: flex;
	flex-direction: row;
}
.instagramName {
	font-size: 16px;
	font-weight: 600;
	word-break: break-word;
}
.logoWrapper {
	background: transparent;
	border: 2px solid rgba(89, 90, 92, 0.72);
	padding: 4px;
	border-radius: 55px;
}
.logo {
	min-width: 44px;
	width: 44px;
	height: 44px;
	border-radius: 44px;
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.errorBlock {
	border: 1px solid rgba(255, 213, 0, 0.3);
	border-radius: 8px;
	padding: 16px;
	color: #ffd500;
	display: flex;
	margin-bottom: 15px;
}
